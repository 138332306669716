import React from 'react'
import style from './QuoteSection.module.scss'

const QuoteSection = (props) => (
  <section className={style.quoteSection} id={props.id}>
    <div className={style.quoteSectionDiv}>
      {props.children}
    </div>
  </section>
);


export default QuoteSection