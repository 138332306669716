import React from 'react'
import style from './Container.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(style)

const Container = (props) => {

  let containerclassName = cx({
    container: true
  })

  return (
    <div className={containerclassName}>
      {props.children}
    </div>
  );
};

export default Container;
