import React from 'react'
import style from './ImpactReportStat.module.scss'

const ImpactReportStat = (props) => (
  <div className={style.impactReportStat}>
    <span>{props.data}</span>
    {props.metric}
  </div>
)

export default ImpactReportStat