import React from 'react';
import classNames from 'classnames/bind';
import style from './Grid.module.scss';

const cx = classNames.bind(style)

export default class Grid extends React.Component {

  render() {
    let className = cx({
      grid: true,
      gridDoubleColumn: this.props.colStyle === 'double',
      gridTripleColumn: this.props.colStyle === 'triple',
      gridDoubleColumnWeighted: this.props.colStyle === 'weighted',
      gridDoubleColumnWeightedInverse: this.props.colStyle === 'weighted-inverse',
      gridFixedDoubleColumn: this.props.colStyle === 'fixed-double',
    });

    return (
      <div className={className}>{this.props.children}</div>
    );
  }
};
