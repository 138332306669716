import React from 'react'
import style from './Quote.module.scss'

const Quote = ({children}) =>  (
    <div className={style.quote}>
      {children}
    </div>
  );


export default Quote