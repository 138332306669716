import React from 'react';
import style from './Footer.module.scss';

const Footer = ({children}) => (
  <footer className={style.footer}>
    <div className={style.footerLink}>
      {children}
    </div>
  </footer>
)

export default Footer;