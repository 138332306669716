import React from 'react'
import Img from 'gatsby-image';
import BenefitImage from '@components/BenefitImage';
import style from './Benefit.module.scss'


const Benefit = (props) => (
  <div className={style.benefit}>
    <BenefitImage>
      <Img
        alt=''
        fluid={props.fluid}
        />
    </BenefitImage>
    <p>{props.text}</p>
  </div>
)

export default Benefit
