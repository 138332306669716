import React from 'react'
import style from './Text.module.scss'

const Text = ({children}) =>  (
    <p className={style.text}>
      {children}
    </p>
  );


export default Text