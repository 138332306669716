import React from 'react'
import style from './FacebookLogo.module.scss'

const FacebookLogo = () => (
  <div className={style.logo}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' ariaLabelledby='facebook-title' role='img' id='facebook-logo'>
      <title id='facebook-title'>Facebook Logo</title>
      <g fill='none' fillRule='nonzero'>
        <path fill='#1877F2' d='M100 50c0-27.614-22.386-50-50-50S0 22.386 0 50c0 24.956 18.284 45.642 42.188 49.393v-34.94H29.492V50h12.695V38.984c0-12.53 7.465-19.453 18.886-19.453 5.47 0 11.193.977 11.193.977v12.305H65.96c-6.211 0-8.148 3.854-8.148 7.808V50H71.68l-2.217 14.453h-11.65v34.94C81.715 95.642 100 74.956 100 50'/>
        <path fill='#FFF' d='M71.68 50H57.812v-9.38c0-3.953 1.938-7.807 8.149-7.807h6.305V20.508s-5.722-.977-11.193-.977c-11.42 0-18.886 6.922-18.886 19.453V50H29.492v14.453h12.695v34.94c2.546.399 5.155.607 7.813.607 2.658 0 5.267-.208 7.813-.607v-34.94h11.65L71.68 50z'/>
      </g>
    </svg>
  </div>
)
export default FacebookLogo