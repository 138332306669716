
import React from 'react'
import style from './OrderedList.module.scss'

const OrderedList = (props) => (
  <ol className={style.orderedList} aria-labelledby={props.ariaLabelledby}>
    {props.children}
  </ol>
)

export default OrderedList;