import React from 'react'
import style from './Section.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(style)

const Section = (props) => {

  const sectionClassNames = cx({
    section: true,
    sectionAccent: props.accent,
    sectionAccentBlue: props.accent === 'blue',
    sectionAccentGreen: props.accent === 'green',
    sectionAccentGrey: props.accent === 'grey',
    sectionAccentBlack: props.accent === 'black',
  })

  return (
    <section className={sectionClassNames} id={props.id}>
      {props.children}
    </section>
  );
}

export default Section