import React from 'react'
import style from './PetsLogo.module.scss'

const PetsLogo = () => (
  <div className={style.logo}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' ariaLabelledby='pah-title' role='img' id='pah-logo'>
      <title id='pah-title'>Pets at Home Logo</title>
        <g fill="none" fillRule="nonzero">
          <path fill="#2EA948" d="M96.673.026H3.11C1.287.026 0 1.126 0 3.207v93.461c0 2.082 1.287 3.182 3.106 3.182h93.565c1.822 0 3.109-1.1 3.109-3.182V3.208c0-2.082-1.287-3.182-3.107-3.182"/>
          <path fill="#FFFFFE" d="M22.484 34.836c-1.444 0-2.586.88-3.68 2.197v8.168c.922.442 1.666.661 3.025.661 2.233 0 3.9-1.714 3.9-5.89 0-3.6-1.359-5.136-3.245-5.136zm-10.297-5.488h5.831l.435 2.852c1.493-1.669 3.42-3.294 6.179-3.294 4.425 0 7.712 3.338 7.712 10.63 0 8.172-4.294 11.552-9.029 11.552-1.926 0-3.284-.349-4.51-.749v6.224l-6.617 1.093V29.348zM43.728 33.2c-1.846 0-3.167 1.62-3.385 4.909h6.769c-.218-3.242-1.366-4.909-3.384-4.909zm9.58 8.547H40.385c.352 3.368 2.373 4.685 5.275 4.685 2.372 0 4.703-.878 7.077-2.233l.66 4.64c-2.331 1.494-5.188 2.411-8.572 2.411-6.46 0-11.076-3.418-11.076-11.084 0-7.007 4.305-11.26 10.151-11.26 6.596 0 9.536 4.91 9.536 11.13 0 .657-.04 1.359-.13 1.711zm11.34 9.347c-4.577 0-7.753-1.67-7.753-7.15v-9.257h-2.833V29.29h2.833v-6.407l6.536-1.009v7.416h4.885l-.01 5.396H63.43v8.2c0 1.755.825 2.548 2.61 2.548.567 0 1.09-.09 1.566-.221l.83 5.443c-1.047.308-2.047.437-3.788.437m14.201 0c-3.418 0-6.616-.966-8.85-2.152l.79-5.052c2.538 1.578 5.653 2.545 8.107 2.545 1.836 0 2.713-.617 2.713-1.623 0-1.1-.615-1.583-3.635-2.33-5.389-1.405-7.67-2.85-7.67-6.896 0-3.998 2.977-6.68 8.238-6.68 2.893 0 5.695.57 7.929 1.581l-.834 5.053c-2.319-1.274-4.991-2.02-7.048-2.02-1.58 0-2.279.573-2.279 1.404 0 .883.611 1.361 3.635 2.2 5.692 1.537 7.71 2.985 7.71 6.986 0 4.524-3.11 6.984-8.806 6.984"/>
          <path fill="#004326" d="M19.098 65.898c-2.425.373-3.087 1.167-3.087 2.172 0 .728.446 1.122 1.107 1.122.685 0 1.345-.303 1.98-.86v-2.434zm.401 5.586l-.235-1.428c-.942.985-2.052 1.663-3.632 1.663-1.837 0-3.132-1.1-3.132-3.18 0-2.688 2.072-3.953 6.598-4.347v-.211c0-1.005-.566-1.45-1.745-1.45-1.296 0-2.497.327-3.722.863l-.424-2.665c1.32-.537 2.804-.885 4.69-.885 3.183 0 4.76 1.05 4.76 3.971v7.669h-3.158zm9.265.079c-2.438 0-4.131-.893-4.131-3.82v-4.95h-1.508v-2.88h1.508v-3.548l3.48-.584v4.133h2.537l.06 2.88h-2.597v4.382c0 .94.44 1.365 1.39 1.365.304 0 .582-.05.838-.12l.44 2.905c-.56.165-1.09.237-2.017.237m13.945-.157v-7.182c0-.818-.327-1.38-1.165-1.38-.676 0-1.303.398-1.935 1.03v7.532h-3.515V54.488l3.515-.582v7.463c.957-.936 2.003-1.66 3.403-1.66 2.116 0 3.238 1.334 3.238 3.556v8.141h-3.54m10.015-8.83c-1.367 0-2.098 1.185-2.098 3.049 0 1.838.755 3.094 2.098 3.094 1.366 0 2.12-1.232 2.12-3.072 0-1.839-.754-3.072-2.12-3.072zm-.023 8.986c-3.177 0-5.514-2.257-5.514-5.915 0-3.654 2.359-5.96 5.537-5.96 3.204 0 5.556 2.28 5.556 5.938 0 3.654-2.377 5.938-5.579 5.938zm19.373-.156V64.21c0-.82-.302-1.383-1.116-1.383-.63 0-1.238.4-1.867 1.009v7.57H65.57V64.21c0-.82-.305-1.383-1.118-1.383-.655 0-1.238.4-1.87 1.009v7.57h-3.52V59.922h3.103l.257 1.57c1.024-1.056 2.077-1.804 3.544-1.804 1.563 0 2.428.771 2.848 1.804 1.027-1.033 2.145-1.804 3.57-1.804 2.121 0 3.241 1.335 3.241 3.562v8.156h-3.55m9.636-9.44c-.982 0-1.681.864-1.8 2.61h3.6c-.118-1.722-.727-2.61-1.8-2.61zm5.093 4.545h-6.869C80.122 68.3 81.2 69 82.737 69c1.265 0 2.503-.465 3.762-1.188l.352 2.469c-1.24.789-2.756 1.28-4.556 1.28-3.437 0-5.889-1.816-5.889-5.89 0-3.727 2.29-5.984 5.397-5.984 3.504 0 5.072 2.607 5.072 5.913 0 .35-.024.722-.07.91z"/>
      </g>
    </svg>
  </div>
)
export default PetsLogo