import React from 'react'
import style from './HertzLogo.module.scss'

const HertzLogo = () => (
  <div className={style.logo}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' ariaLabelledby='hertz-title' role='img' id='hertz-logo'>
      <title id='hertz-title'>Hertz Logo</title>
      <g fill='none' fill-rule='nonzero'>
        <path fill='#000' d='M71.138 57.547c-.838-.89-.337-2.66.092-4.66l1.465-6.79h6.021l1.09-5.053h-6.024L74.85 36h-5.675l-3.72 17.354c-.561 2.59-.771 4.619.37 6.802 1.061 2.03 4.684 3.97 9.272 2.744l1.014-4.69c-3.111.38-4.361-.02-4.973-.663M77 62.856h17.73l1.087-5.054H85.53L98.396 45.86 99.441 41H81.71l-1.093 5.05h10.347L78.035 58.048zM55.605 43.298c-1.987 1.636-3.115 3.777-3.847 7.159L49 63.253h5.681l2.29-10.612c.54-2.482.953-4.035 1.9-4.99 1.3-1.311 3.185-1.383 5.448-1.2l1.092-5.054c-3.991-1-7.477-.02-9.806 1.9M40.188 40.524c-1.918 0-4.542.585-7.121 2.581-5.813 4.498-8.7 20.073 3.784 20.073 3.236 0 6.837-1.01 9.336-2.869l-2.259-3.76c-1.89 1.432-11.406 4.549-10.404-3.058H48.4c.007-.015 1.898-6.097-.829-9.776-2.324-3.136-6.601-3.191-7.383-3.191m3.695 8.34h-9.108c.32-1.208 2.255-4.184 5.847-3.858 3.862.351 3.26 3.859 3.26 3.859M22.489 36L19.78 48.531H9.819L12.52 36H6.792L1 62.899h5.725l1.999-9.319h9.966l-2.006 9.319h5.677L28.159 36z'/>
        <path fill='#FFD100' d='M94.443 65.324H.5l1.138 4.998 93.862.002z'/>
      </g>
    </svg>
  </div>
)
export default HertzLogo