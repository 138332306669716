import React from 'react'
import style from './BenefitsIntro.module.scss'

const BenefitsIntro = ({children}) => (
  <div className={style.benefitsIntro}>
    {children}
  </div>
)

export default BenefitsIntro
