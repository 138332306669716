import React from 'react'
import Img from 'gatsby-image';
import style from './Reason.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(style)

const Reason = (props) => {

  let classname = cx({
    reasonCopy: true,
    reasonCopyRightAlign: props.rightAlign
  })

  return (
    <div className={style.reason}>
      <Img className={style.reasonImage} fluid={props.fluid} alt='' />
      <div className={classname}>
        {props.children}
      </div>
    </div>
  )
}

export default Reason