import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

import AxaLogo from '@logos/AxaLogo';
import BaLogo from '@logos/BaLogo';
import ConfusedLogo from '@logos/ConfusedLogo';
import DftLogo from '@logos/DftLogo';
import DisneyLogo from '@logos/DisneyLogo';
import FacebookLogo from '@logos/FacebookLogo';
import GoogleLogo from '@logos/GoogleLogo';
import HertzLogo from '@logos/HertzLogo';
import MarriottLogo from '@logos/MarriottLogo';
import NationwideLogo from '@logos/NationwideLogo';
import PetsLogo from '@logos/PetsLogo';
import SamaritansLogo from '@logos/SamaritansLogo';
import TescoLogo from '@logos/TescoLogo';
import TuiLogo from '@logos/TuiLogo';
import WaitroseLogo from '@logos/WaitroseLogo';
import ZurichLogo from '@logos/ZurichLogo';
import CxLogo from '@logos/CxLogo';
import RlLogo from '@logos/RlLogo';

import ActionText from '@components/ActionText';
import Attribution from '@components/Attribution';
import Benefit from '@components/Benefit';
import BenefitsIntro from '@components/BenefitsIntro';
import Container from '@components/Container';
import Footer from '@components/Footer';
import Contact from '@components/Contact';
import Grid from '@components/Grid';
import Header from '@components/Header';
import ImpactReport from '@components/ImpactReport';
import ImpactReportStat from '@components/ImpactReportStat';
import IntroText from '@components/IntroText';
import LogoSection from '@components/LogoSection';
import Navbar from '@components/Navbar';
import Quote from '@components/Quote';
import QuoteImage from '@components/QuoteImage';
import QuoteSection from '@components/QuoteSection';
import OrderedList from '@components/OrderedList';
import Reason from '@components/Reason';
import Row from '@components/Row';
import Section from '@components/Section';
import SEO from '@components/SEO';
import Text from '@components/Text'

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        brain: file(
          sourceInstanceName: { eq: "images" }
          name: { eq: "brain" }
        ) {
          childImageSharp {
            fluid(maxWidth: 228, quality:100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        chart: file(
          sourceInstanceName: { eq: "images" }
          name: { eq: "chart" }
        ) {
          childImageSharp {
            fluid(maxWidth: 228, quality:100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        eyechat: file(
          sourceInstanceName: { eq: "images" }
          name: { eq: "eyechat" }
        ) {
          childImageSharp {
            fluid(maxWidth: 228, quality:100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        bulbs: file(
          sourceInstanceName: { eq: "images" }
          name: { eq: "bulbs" }
        ) {
          childImageSharp {
            fluid(maxWidth: 228, quality:100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        pash: file(
          sourceInstanceName: { eq: "images" }
          name: { eq: "pash" }
        ) {
          childImageSharp {
            fluid(maxWidth: 400, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        research: file(
          sourceInstanceName: { eq: "images" }
          name: { eq: "remote-research-session" }
        ) {
          childImageSharp {
            fluid (quality: 100){
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        workshop: file(
          sourceInstanceName: { eq: "images" }
          name: { eq: "remote-workshop" }
        ) {
          childImageSharp {
            fluid (quality: 100){
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
  render={data => (
      <>
      <SEO />
      <Navbar
        navItems={['Benefits', 'Why it works', 'How it works', 'Find out more']}
        brand='Remote Labs'
      >
        <RlLogo />
      </Navbar>
      <Header>
        <Container>
          <Row paddingHorizontal>
            <Grid>
              <h2>Stay close to customers with our award winning, low-cost, managed user-research service</h2>
            </Grid>
          </Row>
        </Container>
      </Header>
      <Section accent='grey'>
        <Container>
          <Row paddingHorizontal>
            <Grid colStyle='fixed-double'>
              <IntroText>Remote Labs deliver positive action and results quickly, through customer insight and experience design</IntroText>
            </Grid>
          </Row>
        </Container>
      </Section>
      <Section id='benefits'>
        <Container>
          <Row paddingTop paddingHorizontal>
            <Grid>
              <BenefitsIntro>
                <h3>Find out what your customers really think</h3>
                <Text>A one-day workshop including moderated user-testing of your digital experience.</Text>
              </BenefitsIntro>
            </Grid>
          </Row>
          <Row padding paddingHorizontal>
            <Grid colStyle='double'>
              <Benefit
                text='De-risk digital investment'
                fluid={data.chart.childImageSharp.fluid}
              />
              <Benefit
                text='Understand your customers'
                fluid={data.bulbs.childImageSharp.fluid}
              />
            </Grid>
          </Row>
          <Row padding paddingHorizontal>
            <Grid colStyle='double'>
              <Benefit
                text='Inspire, focus and motivate'
                fluid={data.brain.childImageSharp.fluid}
              />
              <Benefit
                text='Quick-wins that work'
                fluid={data.eyechat.childImageSharp.fluid}
              />
            </Grid>
          </Row>
        </Container>
      </Section>
      <QuoteSection>
        <Row wide>
          <QuoteImage>
            <Img fluid={data.pash.childImageSharp.fluid} alt='' />
          </QuoteImage>
          <Quote>
            <blockquote>cxpartners is a business that helps to deliver real change — practical and actual changes that customers see and appreciate</blockquote>
            <Attribution
              person='Richard Pash'
              title='Marketing Director, Zurich UK'
            />
          </Quote>
        </Row>
      </QuoteSection>
      <LogoSection>
        <TuiLogo />
        <HertzLogo />
        <NationwideLogo />
        <GoogleLogo />
        <DisneyLogo />
        <ZurichLogo />
        <TescoLogo />
        <PetsLogo />
        <BaLogo />
        <AxaLogo />
        <WaitroseLogo />
        <DftLogo />
        <MarriottLogo />
        <SamaritansLogo />
        <FacebookLogo />
        <ConfusedLogo />
      </LogoSection>
      <Section>
        <Container>
          <Row paddingBottom center>
            <Grid>
              <h3>We are trusted by leading global brands to connect leadership teams to their customers</h3>
            </Grid>
          </Row>
          <Row wide>
            <Grid colStyle='triple'>
              <ImpactReport>
                <blockquote>This is prevention through digital technology at its best. In short, FRANK is giving young people clear, unbiased information in a way they are comfortable with accessing.</blockquote>
                <Attribution
                person='Duncan Selbie'
                title='CEO Public Health England'
                />
                <ImpactReportStat
                  data='39%'
                  metric='increase in site traffic'
                />
                <ImpactReportStat
                  data='200%'
                  metric='increase in site speed'
                />
              </ImpactReport>
              <ImpactReport>
                <blockquote>cxpartners cut through the rubbish that every business has - the red tape, the preconceptions, the ridiculous business processes. They pushed us to transform. What previously took us six months to do internally, we’ve just done in three weeks.</blockquote>
                <Attribution
                  person='Stephen Allen'
                  title='UX and Design Manager, RAC'
                />
                <ImpactReportStat
                  data='8%'
                  metric='increase in sales'
                />
                <ImpactReportStat
                  data='18%'
                  metric='increase in conversion'
                />
              </ImpactReport>
              <ImpactReport>
                <blockquote>You always have wish lists of features. cxpartners set our priorities right and got us away from the HIPPO thing. They showed us how to get the business benefit back.</blockquote>
                <Attribution
                person='Dirk Schmidt'
                title='Head of Customer Processes, IT, Coop Switzerland'
                />
                <ImpactReportStat
                  data='12.6%'
                  metric='increase in sales'
                />
                <ImpactReportStat
                  data='0'
                  metric='complaints'
                />
              </ImpactReport>
            </Grid>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row center paddingTop>
            <Grid>
              <h3 id='why-it-works'>Why Remote Labs work</h3>
            </Grid>
          </Row>
          <Row wide>
            <Grid>
              <Reason fluid={data.workshop.childImageSharp.fluid}>
                <h4>From insight to action - in just one day</h4>
                <p>Your team will watch five remote user research interviews and see first-hand their pain-points and what’s important to them.</p>
                <p>Using the observations, we will work together with you to create an action plan of quick wins and long term solutions.</p>
              </Reason>
            </Grid>
          </Row>
          <Row paddingBottom wide>
            <Grid>
              <Reason rightAlign fluid={data.research.childImageSharp.fluid}>
                <h4>Remote collaboration</h4>
                <p>Remote Labs are designed to fit around the working from home day.</p>
                <p>No matter what your team’s working schedule or pattern might be, we can run sessions over one full day or two shorter days.</p>
              </Reason>
            </Grid>
          </Row>
        </Container>
      </Section>
      <Section accent='green'>
        <Container>
          <Row padding extraPaddingBottom center>
            <Grid>
              <h3>Designed with Google</h3>
              <Text>We devised our methodology with Google experts to help brands improve their mobile web experiences.</Text>
            </Grid>
          </Row>
        </Container>
      </Section>
      <Section id='how-it-works'>
        <Container>
          <Row padding center>
            <Grid>
              <h3 id='how-it-works-title'>How Remote Labs work</h3>
              <OrderedList ariaLabelledby='how-it-works-title'>
                <li><strong>Meet the team</strong><br />
                  <p>You’ll have an initial call with our team, so we can understand the challenge and decide how we can work best together.</p>
                </li>
                <li><strong>Research and record</strong><br />
                  <p>Before your lab starts, we conduct and record detailed interviews with people who match your customer profile as they use your digital experience.</p>
                </li>
                <li><strong>Note taking and sharing</strong><br />
                  <p>At the start of the lab, your combined team watches the user interviews using a secure YouTube channel. Everyone is given the chance to take notes and share observations.</p>
                </li>
                <li><strong>Playback and planning workshop</strong><br />
                  <p>Then, our consultants will lead an insight prioritisation workshop — and, together with your team, devise an action plan to improve your digital experience and solve your business challenge.</p>
                </li>
                <li><strong>Action plan</strong><br />
                  <p>Our consultants will deliver an action plan you can implement immediately, for quick wins and longer term solutions.</p>
                </li>
              </OrderedList>
            </Grid>
          </Row>
        </Container>
      </Section>
      <Section id='find-out-more' accent='blue'>
        <Container>
          <Row paddingTop center>
            <Grid>
              <h3>Find out how we can help your business</h3>
              <ActionText>
                <p>Get in touch so we can hear about your challenge and set up a lab for you.</p>
              </ActionText>
              <Contact cta='Contact us' tel='+44 (0) 117 930 3530'/>
            </Grid>
          </Row>
        </Container>
      </Section>
      <Section accent='blue'>
        <Footer>
          <a
            aria-label='link to c x partners main website'
            href='https://www.cxpartners.co.uk'>
            <CxLogo />
          </a>
        </Footer>
      </Section>
    </>
  )}
  />
);

export default IndexPage;
