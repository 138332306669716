import React, { Component } from 'react';
import { ReactComponent as MenuIcon } from '@static/icons/menu.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import classNames from 'classnames/bind';
import style from './Navbar.module.scss';
const cx = classNames.bind(style);

class Navbar extends Component {
  state = {
    mobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  render() {
    const { mobileMenuOpen } = this.state;
    const navbarClassNames = cx({
      navbarWrapper: true,
      navbarMenuMobile: mobileMenuOpen,
    })

    return (
      <nav className={style.navbar}>
        <div className={navbarClassNames}>
          <div className={style.navbarBrand}>
            <AnchorLink href='#header'>
              {this.props.children}
              <h1>{this.props.brand}</h1>  
            </AnchorLink>
          </div>
          <div className={style.navbarMenu}>
            <Scrollspy
              items={this.props.navItems.map(item => item.toLowerCase())}
              currentClassName='active'
              offset={-150}
            >
              {this.props.navItems.map(navItem => (
                <li key={navItem}>
                  <AnchorLink 
                    href={`#${navItem.replace(/\s+/g, '-').toLowerCase()}`} 
                    onClick={this.closeMobileMenu}
                  >
                    {navItem}
                  </AnchorLink>
                </li>
              ))}
            </Scrollspy>
          </div>
          <button 
            id="menu-button"
            aria-label="menu toggle button"
            className={style.navbarMobileMenuButton}
            onClick={this.toggleMobileMenu}
            >
            <MenuIcon />
          </button>
        </div>
      </nav>
    );
  }
}

export default Navbar;
