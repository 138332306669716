import React from 'react'
import style from './WaitroseLogo.module.scss'

const WaitroseLogo = () => (
  <div className={style.logo}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' ariaLabelledby='waitrose-title' role='img' id='waitrose-logo'>
      <title id='waitrose-title'>Waitrose Logo</title>
      <g fill='#7BB134' fillRule='nonzero'>
        <path d='M17.636 60.735l-5.06-12.165-5.06 12.165L0 42.49h3.049l4.516 11.768 5.011-12.296 4.994 12.296 4.533-11.768h3.049l-7.516 18.245M34.564 53.794c0 2.607-1.418 4.337-4.187 4.337-2.01 0-3.923-1.485-3.923-4.304 0-2.932 1.912-4.3 3.923-4.3 2.77 0 4.187 1.663 4.187 4.267zm2.72 6.51V47.35h-2.72v1.697c-.874-1.237-2.456-1.945-4.187-1.945-3.708 0-6.725 2.818-6.725 6.725 0 4.005 3.248 6.693 6.725 6.693 1.73 0 3.23-.76 4.187-1.98v1.764h2.72m3.84-16.034c-.874 0-1.566-.68-1.566-1.553 0-.873.692-1.582 1.566-1.582.874 0 1.55.709 1.55 1.582a1.53 1.53 0 01-1.55 1.553zm-1.368 3.08h2.72v12.954h-2.72V47.35zM49.382 49.709v10.595h-2.72V49.709H44.14V47.35h2.522v-4.662L49.382 40v7.35h2.901v2.359h-2.901M61.167 49.988c-.396-.232-.857-.46-1.698-.46-1.582 0-2.802.987-2.802 3.148v7.628h-2.72V47.35h2.72v1.815c.775-1.469 1.945-2.063 3.247-2.063.94 0 2.176.282 3.066 1.105l-1.813 1.78m12.214 3.807c0 2.49-1.533 4.337-4.072 4.337-2.554 0-4.054-1.848-4.054-4.337 0-2.49 1.5-4.266 4.054-4.266 2.539 0 4.072 1.776 4.072 4.266zm2.785 0c0-3.874-3.05-6.692-6.857-6.692-3.824 0-6.84 2.818-6.84 6.692 0 3.873 3.016 6.76 6.84 6.76 3.808 0 6.857-2.887 6.857-6.76m5.769 6.76c-1.352 0-2.67-.313-4.4-1.237V56.55c1.268.874 3.015 1.68 4.318 1.68 1.054 0 1.928-.245 1.928-1.203 0-.658-.23-1.089-1.582-1.848l-2.357-1.317c-1.484-.856-2.258-1.797-2.258-3.164 0-2.093 1.566-3.595 4.384-3.595 1.302 0 2.687.333 3.956 1.008v2.684c-1.187-.756-2.654-1.35-3.956-1.35-1.038 0-1.665.396-1.665 1.105 0 .426.05.675 1.154 1.333l2.258 1.304c1.813 1.038 2.802 1.912 2.802 3.574 0 2.443-1.912 3.793-4.582 3.793m15.048-8.274h-6.246c.412-1.566 1.55-2.802 3.313-2.802 1.681 0 2.851 1.152 2.933 2.802zM100 54.503v-.199c0-4.827-2.423-7.202-5.95-7.202-3.462 0-6.28 2.62-6.28 6.527 0 4.583 3.164 6.924 6.692 6.924 1.862 0 3.708-.56 4.813-1.713l-1.73-1.7c-.792.612-1.748.991-3.083.991-2.374 0-3.61-1.73-3.874-3.628H100'/>
      </g>
    </svg>
  </div>
)
export default WaitroseLogo