import React from 'react'
import classNames from 'classnames/bind'
import style from './Row.module.scss'

const cx = classNames.bind(style)

const Row = props => {
  
  let className = cx({
    row: true,
    rowCenterAlign: props.center,
    rowLeftAlign: !props.center,
    rowPadding: props.padding,
    rowPaddingHorizontal: props.paddingHorizontal,
    rowWide: props.wide,
    rowPaddingBottom: props.paddingBottom,
    rowExtraPaddingBottom: props.extraPaddingBottom,
    rowPaddingTop: props.paddingTop,
  })

  return (
    <div className={className}>
      {props.children}
    </div>
  )
}

export default Row