import React from 'react'
import style from './GoogleLogo.module.scss'

const GoogleLogo = () => (
  <div className={style.logo}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' ariaLabelledby='google-title' role='img' id='google-logo'>
      <title id='google-title'>Google Logo</title>
      <g fill='none' fillRule='nonzero'>
        <path fill='#EA4335' d='M42.87 50.474c0 4.73-3.7 8.215-8.24 8.215-4.541 0-8.241-3.485-8.241-8.215 0-4.763 3.7-8.215 8.24-8.215 4.541 0 8.241 3.452 8.241 8.215zm-3.607 0c0-2.955-2.144-4.978-4.633-4.978-2.49 0-4.634 2.023-4.634 4.978 0 2.926 2.145 4.978 4.634 4.978 2.489 0 4.633-2.056 4.633-4.978z'/>
        <path fill='#FBBC05' d='M60.648 50.474c0 4.73-3.7 8.215-8.24 8.215-4.541 0-8.241-3.485-8.241-8.215 0-4.76 3.7-8.215 8.24-8.215 4.541 0 8.241 3.452 8.241 8.215zm-3.607 0c0-2.955-2.145-4.978-4.634-4.978-2.488 0-4.633 2.023-4.633 4.978 0 2.926 2.145 4.978 4.633 4.978 2.49 0 4.634-2.056 4.634-4.978z'/>
        <path fill='#4285F4' d='M77.685 42.756v14.748c0 6.066-3.578 8.544-7.807 8.544-3.982 0-6.378-2.663-7.282-4.84l3.141-1.308c.56 1.337 1.93 2.915 4.137 2.915 2.707 0 4.385-1.67 4.385-4.815v-1.181h-.126c-.807.996-2.363 1.866-4.326 1.866-4.107 0-7.87-3.578-7.87-8.181 0-4.637 3.763-8.245 7.87-8.245 1.96 0 3.515.87 4.326 1.837h.126V42.76h3.426v-.003zm-3.17 7.748c0-2.893-1.93-5.008-4.385-5.008-2.49 0-4.574 2.115-4.574 5.008 0 2.863 2.085 4.948 4.574 4.948 2.455 0 4.385-2.085 4.385-4.948z'/>
        <path fill='#34A853' d='M83.333 34.111v24.074h-3.518V34.111z'/>
        <path fill='#EA4335' d='M97.044 53.178l2.8 1.866c-.903 1.337-3.081 3.641-6.844 3.641-4.667 0-8.152-3.607-8.152-8.215 0-4.885 3.515-8.214 7.748-8.214 4.263 0 6.348 3.392 7.03 5.225l.374.934-10.981 4.548c.84 1.648 2.148 2.489 3.981 2.489 1.837 0 3.111-.904 4.044-2.274zm-8.618-2.956l7.34-3.048c-.403-1.026-1.618-1.74-3.047-1.74-1.834 0-4.386 1.618-4.293 4.788z'/>
        <path fill='#4285F4' d='M13.07 48.337v-3.485h11.745c.115.607.174 1.326.174 2.104 0 2.614-.715 5.848-3.019 8.151-2.24 2.334-5.103 3.578-8.896 3.578-7.03 0-12.94-5.726-12.94-12.755 0-7.03 5.91-12.756 12.94-12.756 3.889 0 6.66 1.526 8.74 3.515l-2.458 2.46c-1.493-1.4-3.515-2.49-6.286-2.49-5.133 0-9.148 4.137-9.148 9.27 0 5.134 4.015 9.271 9.148 9.271 3.33 0 5.226-1.337 6.441-2.552.985-.985 1.633-2.392 1.889-4.315l-8.33.004z'/>
      </g>
    </svg>
  </div>
)
export default GoogleLogo