import React from 'react'
import style from './TuiLogo.module.scss'

const TuiLogo = () => (
  <div className={style.logo}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' ariaLabelledby='tui-title' role='img' id='tui-logo'>
      <title id='tui-title'>TUI Logo</title>
      <path fill='#DB2128' fillRule='nonzero' d='M91.104 66.748c-.229.186-.536.36-.873.52-.558.28-1.163.563-1.714.563-.378 0-.652-.101-.862-.275a1.424 1.424 0 01-.228-.243c-.046-.061-.088-.124-.133-.182-.144-.185-.288-.454-.429-.79-.877.864-2.443 1.595-4.72 1.595-1.925 0-3.45-.49-4.54-1.522-1.062-1.005-1.667-2.452-1.667-4.45V50.56c0-.483.097-.81.443-1.07.357-.274 1.04-.366 1.91-.366.85 0 1.535.094 1.89.37.341.26.437.585.437 1.066V61.44c0 1.62.664 2.419 2.576 2.419 1.917 0 3.049-1.142 3.049-2.707V50.559c0-.483.097-.81.443-1.07.356-.273 1.011-.366 1.884-.366.849 0 1.534.094 1.89.37.34.26.437.585.437 1.066v12.664c0 .419.066.792.145 1.092.076.291.164.512.215.637.155.313.296.617.296 1.024-.001.342-.236.6-.449.772zM70.238 53.553v12.646c0 .483-.098.81-.444 1.07-.356.273-1.038.366-1.883.366-.874 0-1.56-.094-1.916-.37-.342-.26-.438-.586-.438-1.066V53.891l-2.157.156c-1.931.14-2.007-1.5-2.007-2.41 0-1.803.665-2.448 1.86-2.449h9.3c.913 0 1.6.29 1.87 1.594.301 1.449.178 2.458-1.443 2.573l-2.742.198zm29.26 13.755c-.357.273-1.04.366-1.884.366-.874 0-1.56-.094-1.916-.37-.341-.26-.437-.586-.437-1.066v-15.68c0-.482.097-.81.443-1.069.357-.274 1.04-.366 1.91-.366.85 0 1.534.094 1.89.37.341.26.437.585.437 1.066v15.68c0 .482-.097.81-.443 1.069zM2.47 40.589h12.362c1.211 0 2.125.383 2.485 2.104.4 1.914.235 3.246-1.92 3.399l-3.88.276c2.856 18.05 19.557 23.932 28.65 4.493 1.505-3.217 1.998-3.803 3.646-3.394 2.235.555 2.54 1.704 1.476 4.948-7.862 23.964-34.286 26.366-39.47-5.636l-3.153.227C.1 47.191 0 45.026 0 43.822c0-2.38.884-3.233 2.47-3.233zM47.416 29a4.588 4.588 0 110 9.176 4.588 4.588 0 010-9.176z'/>
    </svg>
  </div>
)
export default TuiLogo