import React from 'react'
import style from './TescoLogo.module.scss'

const TescoLogo = () => (
  <div className={style.logo}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' ariaLabelledby='tesco-title' role='img' id='tesco-logo'>
      <title id='tesco-title'>Tesco Logo</title>
      <g fill='none' fillRule='nonzero' stroke='#FFF'>
        <path fill='#0054A4' strokeWidth='.079' d='M7.633 58.57c-2 0-3.62 1.144-4.402 1.956 0 0-1.903 1.886-3.192 2.539-.056.03-.055.093.023.093H11.71c2.303 0 3.385-.78 4.752-2.003 0 0 1.99-1.85 3.354-2.446.137-.052.105-.14 0-.14H7.633h0zm20.01 0c-2.001 0-3.62 1.144-4.403 1.956 0 0-1.903 1.886-3.191 2.539-.057.03-.056.093.023.093h11.67c2.302 0 3.385-.78 4.751-2.003 0 0 1.968-1.85 3.331-2.446.138-.052.128-.14.023-.14H27.642h0zm20.031 0c-2 0-3.62 1.144-4.402 1.956 0 0-1.903 1.886-3.192 2.539-.056.03-.054.093.024.093h11.67c2.302 0 3.362-.78 4.729-2.003 0 0 1.99-1.85 3.353-2.446.138-.052.128-.14.024-.14H47.674h0zm20.01 0c-2 0-3.597 1.144-4.38 1.956 0 0-1.926 1.886-3.215 2.539-.056.03-.055.093.024.093h11.67c2.302 0 3.385-.78 4.751-2.003 0 0 1.991-1.85 3.355-2.446.138-.052.104-.14 0-.14H67.683h0zm20.03 0c-2 0-3.62 1.144-4.402 1.956 0 0-1.904 1.886-3.192 2.539-.057.03-.055.093.023.093h11.671c2.302 0 3.384-.78 4.752-2.003 0 0 1.968-1.85 3.33-2.446.138-.052.13-.14.024-.14H87.715h0z'/>
        <path fill='#F02E25' strokeWidth='.03' d='M48.397 36.418c-4.258 0-8.41 1.264-8.41 5.497 0 7.33 12.788 3.458 12.788 7.943 0 1.462-2.267 2.05-4.099 2.05-3.29 0-5.557-.49-8.432-2.213v3.68c2.143.706 5.059 1.048 8.223 1.048 4.416 0 8.548-1.14 8.548-5.474 0-7.654-12.788-4.215-12.788-7.966 0-1.51 2.052-2.026 3.844-2.026 3.016 0 6.271.882 8.06 2.538v-3.913c-2.315-.73-5.388-1.164-7.734-1.164h0zm22.594.046c-7.68 0-12.765 3.692-12.765 9.317 0 5.163 4.667 8.619 11.647 8.619 2.26 0 4.279-.297 6.638-.863v-4.356c-1.668 1.762-3.65 2.47-5.777 2.47-4.122 0-6.941-2.569-6.941-6.267 0-3.647 2.923-6.312 7.01-6.312 2.228 0 4.013.81 5.45 2.213V36.93c-1.494-.308-3.368-.465-5.262-.465h0zm17.911 0c-6.617 0-11.297 3.81-11.297 9.177 0 5.266 4.202 8.76 10.46 8.76 6.647 0 11.226-3.715 11.226-9.108.001-5.292-4.163-8.829-10.389-8.829h0zM.692 37v4.123c1.439-1.115 4.447-1.536 7.733-1.56v11.25c0 1.635-.144 2.101-.838 2.958h6.499c-.73-.857-.84-1.323-.84-2.959V39.468c2.849.025 6.249.539 7.687 1.655V37H.693h0zm21.989 0c.68.856.815 1.344.815 2.981v10.831c0 1.636-.123 2.128-.769 2.959h15.7v-4.076c-2.154 1.533-6.254 1.539-7.92 1.513h-2.282v-4.681h1.677c1.136 0 3.187.134 4.379.49v-3.494c-1.175.377-3.246.49-4.38.49h-1.676v-4.449h2.282c2.04 0 5.587.422 6.918 1.538v-4.1H22.681V37h0zm65.78 2.003c3.466 0 5.753 2.868 5.753 6.406s-2.287 6.406-5.753 6.406c-3.522 0-5.778-2.868-5.778-6.406s2.256-6.406 5.778-6.406z'/>
      </g>
    </svg>
  </div>
)
export default TescoLogo