import React from 'react';
import style from './Contact.module.scss';
import Button from '../Button';

const Contact = ({
  cta,
  tel,
}) => (
  <div className={style.contact}>
    <a className={style.email} href="mailto:daniel.harris@cxpartners.co.uk">
      { cta }
    </a>
    <a className={style.tel} href="tel:+441179303530">
      { tel }
    </a>
  </div>
)

export default Contact;