import React from 'react'
import style from './AxaLogo.module.scss'

const AxaLogo = () => (
  <div className={style.logo}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' ariaLabelledby='axa-title' role='img' id='axa-logo'>
      <title id='axa-title'>AXA Logo</title>
      <g fill='none' fillRule='nonzero'>
        <path fill='#3B5598' d='M1.47 4.576v90.879h97.06V4.575H1.47zm70.762 84.883c-.309-2.1-.587-3.09-.989-4.294L68.185 75.4H54.589l-2.441 3.214 2.194 6.612c.432 1.39.896 2.472 1.699 4.233h-7.725l-1.266-4.233-3.09 4.233h-6.212l-1.33-4.202-3.275 4.202h-6.21c.154-.123.278-.247.401-.37 1.576-1.391 2.565-2.657 3.43-3.8l3.956-5.192-1.546-4.698H19.61L9.01 89.46H2.428c2.504-2.163 3.153-2.842 4.08-4.079l20.085-26.543a9.03 9.03 0 00.741-1.143c.371-.618.71-1.328.897-2.194h6.52c0 1.453.215 2.256.525 3.369l4.572 14.338 2.349-3.09-3.74-11.403c-.277-.834-.617-1.916-1.297-3.214h7.91c.217 1.267.433 2.287.773 3.4l1.452 4.48 3.399-4.51c.773-1.051 1.824-2.226 2.286-3.37h6.922c-1.359 1.082-2.225 2.226-3.059 3.307L49.18 68.974l1.328 4.202 10.97-14.338c.278-.432.588-.803.897-1.205.4-.617.742-1.266.958-2.132h6.458c.093 1.144.247 2.132.556 3.183l8.313 26.513c.4 1.42.68 2.286 1.73 4.264h-8.158v-.002z'/>
        <path fill='#3B5598' d='M22.7 71.197h9.27l-2.812-8.56-6.459 8.56zm18.848 7.231l1.484 4.697 2.38-3.12-1.639-4.605-2.225 3.028zm16.347-7.23h8.961l-2.812-8.56-6.149 8.56z'/>
        <path fill='#CF4044' d='M54.589 53.77h5.685L97.417 4.545h-5.685z'/>
      </g>
    </svg>
  </div>
)

export default AxaLogo