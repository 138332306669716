import React from 'react'
import style from './Attribution.module.scss'

const Attribution = (props) => (
  <div className={style.attribution}>
    <p className={style.attributionPerson}>{props.person}</p>
    <p>{props.title}</p>
  </div>
)

export default Attribution